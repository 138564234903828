import { PARTNER_USER_LIST, TOTAL_PARTNER_USER } from '../../constants/ReducerConstants'

/* eslint-disable comma-spacing */
const initState = {
    partnerUserData: null,
    totalPartnerUserData: null
}
// reducer for partner listing
const PartnerReducer = (state = initState, action) => {
    switch (action.type) {
        case PARTNER_USER_LIST:
            return { ...state, partnerUserData: action.payload }
        case TOTAL_PARTNER_USER:
            return { ...state, totalpartnerUserData: action.payload }
        default:
            return state
    }
}
export default PartnerReducer
