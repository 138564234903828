export const ROLE_LIST = 'ROLE_LIST'
export const ROLE_TOTAL = 'ROLE_TOTAL'
export const MODULE_LIST = 'MODULE_LIST'
export const MODULE_TOTAL = 'MODULE_TOTAL'
export const PARTNER_USER_LIST = 'PARTNER_USER_LIST'
export const TOTAL_PARTNER_USER = 'TOTAL_PARTNER_USER'
export const COUNTRY_LIST = 'COUNTRY_LIST'
export const COUNTRY_TOTAL = 'COUNTRY_TOTAL'
export const STATE_LIST = 'STATE_LIST'
export const STATE_TOTAL = 'STATE_TOTAL'
export const SUPPORT_LIST = 'SUPPORT_LIST'
export const SUPPORT_TOTAL = 'SUPPORT_TOTAL'
export const EVENT_LIST = 'EVENT_LIST'
export const EVENT_TOTAL = 'EVENT_TOTAL'
export const AVAILABILITY_LIST = 'AVAILABILITY_LIST'
export const AVAILABILITY_TOTAL = 'AVAILABILITY_TOTAL'
export const PARTNER_NOTIFICATION_LIST = 'PARTNER_NOTIFICATION_LIST'
export const TOTAL_PARTNER_NOTIFICATION = 'TOTAL_PARTNER_NOTIFICATION'
export const MESSAGE_LIST = 'MESSAGE_LIST'
export const MESSAGE_TOTAL = 'MESSAGE_TOTAL'