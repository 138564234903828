import { AVAILABILITY_LIST, AVAILABILITY_TOTAL } from '../../constants/ReducerConstants'

const initialState = {
    availabilityRequest: [],
    totalAvailabilityRequest: 0
}
// reducer for availability request
const availabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case AVAILABILITY_LIST:
            return { ...state, availabilityRequest: action.payload }
        case AVAILABILITY_TOTAL:
            return { ...state, totalAvailabilityRequest: action.payload }
        default: {
            return state
        }
    }
}
export default availabilityReducer
