import { SUPPORT_LIST, SUPPORT_TOTAL } from '../../constants/ReducerConstants'
const initialState = {
    ticket: [],
    totalTicket: 0
}
// reducer for support request listing
const supportReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUPPORT_LIST:
            return { ...state, ticket: action.payload }
        case SUPPORT_TOTAL:
            return { ...state, totalTicket: action.payload }
        default: {
            return state
        }
    }
}
export default supportReducer
