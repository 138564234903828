import { PARTNER_NOTIFICATION_LIST, TOTAL_PARTNER_NOTIFICATION } from '../../constants/ReducerConstants'

const initState = {
    notificationData: null,
    totalnotification: null
}
// reducer for notification listing
const notificationReducer = (state = initState, action) => {
    switch (action.type) {
        case PARTNER_NOTIFICATION_LIST:
            return { ...state, notificationData: action.payload }
        case TOTAL_PARTNER_NOTIFICATION:
            return { ...state, totalnotification: action.payload }
        default:
            return state
    }
}
export default notificationReducer
