// ** Reducers Imports
import layout from '../layout'
import navbar from '../navbar'
import PartnerReducer from './PartnerReducer'
import supportReducer from './SupportReducer'
import EventReducer from './EventReducer'
import availabilityReducer from './AvailabilityReducer'
import notificationReducer from './notificationReducer'

const rootReducer = { navbar, layout, PartnerReducer, supportReducer, EventReducer, availabilityReducer, notificationReducer }

export default rootReducer
