import { EVENT_LIST, EVENT_TOTAL } from '../../constants/ReducerConstants'
// reducer for availability request

/* eslint-disable comma-spacing */
const initState = {
    event: [],
    totalEvent: 0
}
// reducer for event listing
const EventReducer = (state = initState, action) => {
    switch (action.type) {
        case EVENT_LIST:
            return { ...state, event: action.payload }
        case EVENT_TOTAL:
            return { ...state, totalEvent: action.payload }
        default:
            return state
    }
}
export default EventReducer
